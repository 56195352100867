// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bh_kr";
export var caseStudyBackground__bgColor = "bh_kn";
export var caseStudyBackground__lineColor = "bh_kp";
export var caseStudyHead = "bh_kj";
export var caseStudyHead__imageAbsolute = "bh_kl";
export var caseStudyHead__imageWrapper = "bh_kk";
export var caseStudyProjectsSection = "bh_ks";
export var caseStudyQuote__bgRing = "bh_km";
export var caseStudyTechSection = "bh_kq";
export var caseStudy__bgDark = "bh_kh";
export var caseStudy__bgLight = "bh_kg";