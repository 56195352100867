import { Link } from 'gatsby'
import { StaticImage, getImage } from 'gatsby-plugin-image'

import MainLayout from '~/components/layouts/MainLayout'
import ContactFormSection from '~/components/shared/ContactFormSection'
import SubtractBlock from '~/components/shared/SubtractBlock'
import { CommonImageProps } from '~/types/common-props'
import { fileToImageLikeData } from '~/utils'
import CaseStudyBackground from '~/views/CaseStudies/components/CaseStudyBackground'
import CaseStudyCta from '~/views/CaseStudies/components/CaseStudyCta'
import CaseStudyHead from '~/views/CaseStudies/components/CaseStudyHead'
import CaseStudyPreview from '~/views/CaseStudies/components/CaseStudyPreview'
import CaseStudyPreviewHead from '~/views/CaseStudies/components/CaseStudyPreviewHead'
import CaseStudyQuote from '~/views/CaseStudies/components/CaseStudyQuote'
import CaseStudySolution from '~/views/CaseStudies/components/CaseStudySolution'
import CaseStudySolutionHead from '~/views/CaseStudies/components/CaseStudySolutionHead'
import CaseStudyTech from '~/views/CaseStudies/components/CaseStudyTech'

import * as containerStyles from './Timebot.module.scss'
import useTimebotStaticQuery from './useTimebotStaticQuery'

const imageProps: CommonImageProps = {
  width: 80,
  height: 80,
}

export const quotes = {
  ceo: {
    name: 'Dmytro',
    position: 'CEO & Founder at Codica',
  },
  pm: {
    name: 'Arpi',
    position: 'PM Team Lead at Codica',
  },
  techLead: {
    name: 'Serhii',
    position: 'Tech Lead & Rails expert at Codica',
  },
}

const previewRefTitle = 'Dental Imaging Management System'
const previewWownerTitle = 'Intelligent Job Match System'

const CaseStudiesTimebot = () => {
  const query = useTimebotStaticQuery()

  const bannerCase = getImage(fileToImageLikeData(query.bannerCase))
  const bannerRings = getImage(fileToImageLikeData(query.bannerRings))
  const solutionDeliveredOne = getImage(
    fileToImageLikeData(query.solutionDeliveredOne),
  )
  const solutionDeliveredTwo = getImage(
    fileToImageLikeData(query.solutionDeliveredTwo),
  )
  const solutionDeliveredThree = getImage(
    fileToImageLikeData(query.solutionDeliveredThree),
  )
  const solutionDeliveredFour = getImage(
    fileToImageLikeData(query.solutionDeliveredFour),
  )
  const solutionDeliveredFive = getImage(
    fileToImageLikeData(query.solutionDeliveredFive),
  )
  const solutionDeliveredSix = getImage(
    fileToImageLikeData(query.solutionDeliveredSix),
  )

  return (
    <MainLayout isTransparent scrollTop>
      <div className={containerStyles.caseStudyHead}>
        <CaseStudyHead
          title="Timebot for Slack: Simple work hour tracker"
          subtitle="Learn how Codica created the workday time tracking tool with Node (NestJS), React, and PostgreSQL that boosted team performance and made reporting easier than ever."
          maxWidthSubtitle="340px"
          maxWidthInfo="340px"
          image={bannerCase}
          classNameImageWrapper={containerStyles.caseStudyHead__imageWrapper}
          imageAbsolute={bannerRings}
          classNameImageAbsolute={containerStyles.caseStudyHead__imageAbsolute}
          imageAlt="Simple time tracking software | Codica"
          imageTitle="Time management software | Codica"
          domain="Project management"
          location="Kharkiv, Ukraine"
          timeline="April 2019 - July 2022"
          services={
            <>
              Front-end, Back-end,
              <Link
                to="/services/custom-software-development/"
                className="ml5"
                key="customDevKey"
              >
                Custom software development services
              </Link>
            </>
          }
          team="3 Software Developers, 1 QA Engineer"
          technologies="Node JS, NestJS, React JS, PostgreSQL"
          isQuote
        />

        <CaseStudyQuote
          quote={[
            {
              text: '“At Codica, our values are the high quality of products we deliver and transparent communication with customers. Therefore, our team needed a time tracking solution, to make sure our clients always get regular reports and know the status of each project or a particular task, when needed. So, our team decided to create our own tool, which would work exactly how we need it.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/avatars/CEO.png"
              alt={`${quotes.ceo.name}, ${quotes.ceo.position} at Codica`}
              title={`${quotes.ceo.name}, ${quotes.ceo.position}`}
              {...imageProps}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.ceo.name}
          position={quotes.ceo.position}
        />
      </div>

      <CaseStudyBackground
        backgroundColor={containerStyles.caseStudyBackground__bgColor}
        lineColor={containerStyles.caseStudyBackground__lineColor}
        bgTextLeft={[
          'Codica team started its way by having only two professionals onboard. During more than 7 years of our establishment, we have grown to 60+ employees.',
          <div className="mb-3" key="bgTextLeft" />,
          'Thus, we started looking for options to automate reporting and simplify the workday time tracking process.',
        ]}
        bgTextRight={[
          'We use Slack for corporate communication to discuss all project issues. The Slack App provides an opportunity to create different bots that help to automate everyday routine.',
          <div className="mb-3" key="bgTextLeft" />,
          'That is why we wanted to develop a time bot within the Slack platform.',
        ]}
        sectionBusiness={[
          {
            label:
              'Implement a transparent reporting system for the team and customers.',
          },
          {
            label:
              'Provide employees with a simple and user-friendly time bot for personal project time tracking.',
          },
          {
            label:
              'Develop an advanced administration panel to view the overall performance statistics on individuals, teams, and projects at any chosen time period.',
          },
          {
            label:
              'Include Trello for the time bot to achieve the highest productivity of employees.',
          },
        ]}
        sectionValueDelivered={[
          {
            label:
              'Created high-powered time tracking software. This work time tracker improved our team’s performance and velocity of internal company processes.',
          },
          {
            label:
              'Developed a time bot with a transparent reporting system. It allowed project managers to get the exact data that each specialist (front-end and back-end developers, QAs) spent on a specific task related to a particular project.',
          },
          {
            label:
              'Boosted time management. Thanks to this daily time tracker, project managers are more efficient in monitoring the team’s workload and redistributing the tasks.',
          },
          {
            label:
              'Integrated our TimeBot for Slack with Trello. We customized seamless bundling with Trello to simplify the time tracking process.',
          },
        ]}
      />

      <CaseStudyQuote
        quote={[
          {
            text: '“As a project manager, I firmly believe that time tracking is the key to keeping track of the team’s performance and its analysis, and setting accurate project estimates for future iterations. And that is what TimeBot is great at. By one click, I can access overall performance statistics on individuals, teams, and projects for any time period and at any time.”',
          },
        ]}
        photo={
          <StaticImage
            src="../../../../assets/images/avatars/PM-Arpi.png"
            alt={`${quotes.pm.name}, ${quotes.pm.position} at Codica`}
            title={`${quotes.pm.name}, ${quotes.pm.position}`}
            {...imageProps}
          />
        }
        bgRing={containerStyles.caseStudyQuote__bgRing}
        name={quotes.pm.name}
        position={quotes.pm.position}
      />

      <CaseStudyCta
        backgroundColor={containerStyles.caseStudy__bgDark}
        title="Do you need a reliable team to build a web product?"
        description="We apply best practices to help you save costs and launch quickly."
        nameButton="Talk to an expert"
        linkButton="/contacts/"
      />

      <div className={containerStyles.caseStudy__bgLight}>
        <CaseStudySolutionHead title="Solution delivered" />
        <CaseStudySolution
          image={solutionDeliveredOne}
          title="Auto-generated reports"
          description={[
            'We customized our time tracking software with the administration panel. It provides visualized insights on what tasks and projects the teams or their members are working on.',
            <div className="mb-2" key="descrOne" />,
            'We set up generating and sending reports according to set intervals and triggered tasks.',
            <div className="mb-2" key="descrTwo" />,
            'Moreover, this time attendance software allows our project managers to handle the teams’ performance across different projects and get accurate on-demand productivity reports.',
          ]}
          alt="Auto-generated reports for work time tracker | Codica"
          imageTitle="Auto-generated reports for work hour tracker | Codica"
        />
        <CaseStudySolution
          isReverse
          image={solutionDeliveredTwo}
          title="Easy management"
          description={[
            'Our TimeBot for Slack was developed with an advanced administration panel. With its help, managers can easily create teams and add employees, get summary reports and see who is in and out.',
            <div className="mb-2" key="descrOne" />,
            'Also, it allows project managers to avoid unnecessary employee monitoring and do better planning.',
            <div className="mb-2" key="descrTwo" />,
            'Besides, the reports that our work hour tracker provides help with client billing and communication.',
          ]}
          alt="Easy management for work hour tracker | Codica"
          imageTitle="Easy management for work hour tracker | Codica"
        />
        <CaseStudySolution
          image={solutionDeliveredThree}
          title="Tailored timesheets"
          description={[
            'We created tailored timesheets to make our TimeBot for Slack more efficient. Now, managers have better results with daily, weekly, and monthly timesheets for projects, employees, and clients.',
            <div className="mb-2" key="descr" />,
            'Within each report, there is a possibility to categorize the entries and create customized data sheets that can be exported in CSV files.',
          ]}
          alt="Tailored timesheets for work time tracker | Codica"
          imageTitle="Tailored timesheets for work hour tracker | Codica"
        />
      </div>

      <div className={containerStyles.caseStudy__bgDark}>
        <CaseStudySolution
          isReverse
          isTextWhite
          image={solutionDeliveredFour}
          title="Integration with Trello"
          description="Trello’s tickets with attributes and statuses serve as a description of logged hours. We implemented this integration in our work hour tracker."
          alt="Integration of work hour tracker with Trello | Codica"
          imageTitle="Integration of work hour tracker with Trello | Codica"
        />
        <CaseStudySolution
          isTextWhite
          image={solutionDeliveredFive}
          title="Absence tracking"
          description="Our time tracking software, TimeBot, also simplifies keeping track of absences (vacation, sick leaves, public holidays) for the human resources department. Now, admins can easily access absence reports via the administration panel."
          alt="Simple absence tracking for work time tracker | Codica"
          imageTitle="Simple absence tracking for work hour tracker | Codica"
        />
        <CaseStudySolution
          isReverse
          isTextWhite
          image={solutionDeliveredSix}
          title="Efficient workload monitoring"
          description={[
            'TimeBot hours tracker reminds the employees about workday time tracking. Also, project managers can easily access the data about the balance between reported and estimated working hours.',
            <div className="mb-2" key="descr" />,
            'As a result, project managers are getting more efficient in monitoring the team workload and redistributing the tasks.',
          ]}
          alt="Workload monitoring feature for work time tracker | Codica"
          imageTitle="Workload monitoring feature for work hour tracker | Codica"
        />
      </div>

      <CaseStudyQuote
        quote={[
          {
            text: '“It is not a joke that software developers are lazy. We want to simplify things, to the extent possible. And this is also the reason we wanted to create our own tool, which would allow us to track time in the easiest possible way. Also, Timebot enables our project managers to create reports on numerous categories, and that makes things much easier for them.”',
          },
        ]}
        photo={
          <StaticImage
            src="../../../../assets/images/avatars/Sergey.png"
            alt={`${quotes.techLead.name}, ${quotes.techLead.position} at Codica`}
            title={`${quotes.techLead.name}, ${quotes.techLead.position}`}
            {...imageProps}
          />
        }
        bgRing={containerStyles.caseStudyQuote__bgRing}
        name={quotes.techLead.name}
        position={quotes.techLead.position}
      />

      <div className={containerStyles.caseStudyTechSection}>
        <CaseStudyTech
          technologies={[
            { label: 'Node JS', icon: 'nodejs' },
            { label: 'Nest JS', icon: 'nestjs' },
            { label: 'React JS', icon: 'react' },
            { label: 'TypeORM', icon: 'typeorm' },
            { label: 'PostgreSQL', icon: 'sql' },
            { label: 'Material UI', icon: 'material' },
          ]}
          integrations={[{ label: 'SlackAPI', icon: 'slack' }]}
        />
        <div className="d-none d-lg-block">
          <SubtractBlock />
        </div>
      </div>

      <div className={containerStyles.caseContactFormSection}>
        <ContactFormSection title="Let’s turn your idea into a successful product!" />
      </div>

      <div className={containerStyles.caseStudyProjectsSection}>
        <div className="container case-wrapper-xl">
          <div className="row">
            <CaseStudyPreviewHead title="Other projects" />
            <CaseStudyPreview
              title={previewRefTitle}
              color="refColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/previews/case-ref.png"
                  alt={`Case study: ${previewRefTitle} | Codica`}
                  title={`Case study: ${previewRefTitle}`}
                  placeholder="blurred"
                  width={350}
                  objectFit="fill"
                />
              }
              url="dental-imaging-management-system"
            />
            <CaseStudyPreview
              title={previewWownerTitle}
              color="wownerColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/previews/case-wowner.png"
                  alt={`Case study: ${previewWownerTitle} | Codica`}
                  title={`Case study: ${previewWownerTitle}`}
                  placeholder="blurred"
                  objectFit="fill"
                  width={350}
                />
              }
              url="intelligent-job-matching-service"
            />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default CaseStudiesTimebot
